import swal from "sweetalert";
import {
    addTime,
    updateTime,
    updateTimeCaptureList,
    getTimeTracker,
    timeBillable,
    getAssignedTask,
    removeTimeCapture,
    getUserTimeTracker,
    addUserTime,
    updateUserTime,
    updateUserTimeCaptureList,
    getReviewerTracking,
    getApproverTracking,
    updateTimeCaptureApproveStatus,
    getNote,
    addNote,
    sendToReviewer,
    sendToApprover,
    getPendingTrackingForApprover,
    getApprovedTrackingForApprover,
    getRejectTrackingForApprover,
    updateMultipleTimeCaptureApproveStatus,
    getApproverProject,
    getProjectApproverTracking,
    sendBackApprover,
    reviewerApproveTrackingDetails,
    getApproverTimeCard,
    getReviewerTimeCard
} from '../../services/TimeCaptureService';

import {
    LOADING_TIME_CAPTURE,
    SET_SINGLE_DATA_OBJECT,
    START_TIME_ACTION,
    STOP_TIME_ACTION,
    OFFSET_TIME_ACTION,
    ADD_TIME_CAPTURE,
    UPDATE_TIME_CAPTURE,
    GET_ASSIGNED_TASK_ACTION,
    GET_TIME_CAPTURE,
    GET_TRACKING_DETAILS_ACTION,
    GET_APPROVED_TRACKING_FOR_APPROVER_ACTION,
    GET_REJECT_TRACKING_FOR_APPROVER_ACTION,
    GET_PENDING_TRACKING_FOR_APPROVER_ACTION,
    GET_PROJECT_APPROVER_TRACKING_DETAILS_ACTION
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';

export function addTimeCapture(client_id, project_id, activity_id, task_id, description, billable, start_time, history) {
    return (dispatch) => {
        addTime(client_id, project_id, activity_id, task_id, description, billable, start_time)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(addTimeAction(response.data));
                    dispatch(startTimeAction(true));
                    dispatch(setSingleDataObject({
                        "track_id": response.data.data.insertedId,
                        "client_id": client_id,
                        "project_id": project_id,
                        "activity_id": activity_id,
                        "task_id": task_id,
                        "description": description,
                        "billable": billable,
                    }));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addUserTimeCapture(project_id, activity_id, task_id, description, billable, start_time, history) {
    return (dispatch) => {
        addUserTime(project_id, activity_id, task_id, description, billable, start_time)
            .then((response) => {
                console.log('response.add..', response.data)
                if (response.data.status == 200) {
                    dispatch(addTimeAction(response.data));
                    dispatch(startTimeAction(true));
                    dispatch(setSingleDataObject({
                        "track_id": response.data.data.insertedId,
                        "project_id": project_id,
                        "activity_id": activity_id,
                        "task_id": task_id,
                        "description": description,
                        "billable": billable,
                    }));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateTimeCapture(track_id, client_id, project_id, activity_id, task_id, description, billable, end_time, history) {
    return (dispatch) => {
        updateTime(track_id, client_id, project_id, activity_id, task_id, description, billable, end_time)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(updateTimeAction({ "insertedId": '', "acknowledged": false }));
                    dispatch(setSingleDataObject({ "track_id": '', "project_id": '', "activity_id": '', "task_id": '', "description": '', 'billable': '' }));
                    dispatch(startTimeAction(false));
                    dispatch(stopTimeAction(0));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateUserTimeCapture(track_id, project_id, activity_id, task_id, description, billable, end_time, history) {
    return (dispatch) => {
        updateUserTime(track_id, project_id, activity_id, task_id, description, billable, end_time)
            .then((response) => {
                console.log('response.up..', response.data)
                if (response.data.status == 200) {
                    dispatch(updateTimeAction({ "insertedId": '', "acknowledged": false }));
                    dispatch(setSingleDataObject({ "track_id": '', "client_id": '', "project_id": '', "activity_id": '', "task_id": '', "description": '', 'billable': '' }));
                    dispatch(startTimeAction(false));
                    dispatch(stopTimeAction(0));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateTimeCaptureListData(track_id, client_id, project_id, activity_id, task_id, description, billable, start_time, end_time, filterType, startDate, endDate, page, perPage,orderByField, orderByFieldValue, history) {
    return (dispatch) => {
        updateTimeCaptureList(track_id, client_id, project_id, activity_id, task_id, description, billable, start_time, end_time)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    dispatch(getTimeCaptureDetails(filterType, startDate, endDate, page, perPage,orderByField, orderByFieldValue, history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateUserTimeCaptureListData(track_id, project_id, activity_id, task_id, description, billable, start_time, end_time, filterType, startDate, endDate, page, perPage,orderByField, orderByFieldValue, history) {
    return (dispatch) => {
        updateUserTimeCaptureList(track_id, project_id, activity_id, task_id, description, billable, start_time, end_time)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    dispatch(getTimeCaptureDetails(filterType, startDate, endDate, page, perPage,orderByField, orderByFieldValue,history));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getTimeCaptureDetails(filterType, startDate, endDate, page, perPage,orderByField, orderByFieldValue, history) {
    return (dispatch) => {
        getTimeTracker(filterType, startDate, endDate, page, perPage,orderByField, orderByFieldValue)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeTrackerAction(response.data));
                }
                //dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getUserTimeCaptureDetails(task_id, filterType, startDate, endDate, page, perPage, history) {
    return (dispatch) => {
        getUserTimeTracker(task_id, filterType, startDate, endDate, page, perPage)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeTrackerAction(response.data));
                }
                //dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addTimeBillable(track_id, billable, filterType, startDate, endDate, page, perPage,orderByField, orderByFieldValue, history) {
    return (dispatch) => {
        timeBillable(track_id, billable)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getTimeCaptureDetails(filterType, startDate, endDate, page, perPage,orderByField, orderByFieldValue, history));
                    utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function removeTimeCaptureData(id, filterType, startDate, endDate, page, perPage,orderByField, orderByFieldValue, history) {
    return (dispatch) => {
        removeTimeCapture(id)
            .then((response) => {
                if (response.data.status == 200) {
                    swal(response.data.msg, { icon: "success", });
                    dispatch(getTimeCaptureDetails(filterType, startDate, endDate, page, perPage,orderByField, orderByFieldValue, history));
                } else {
                    swal(response.data.msg, "error");
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getAssignedTaskData(searchString, orderByField, orderByFieldValue, page, perPage, history) {
    return (dispatch) => {
        getAssignedTask(searchString, orderByField, orderByFieldValue, page, perPage)
            .then((response) => {
                // console.log('response....', response.data);
                if (response.data.status == 200) {
                    dispatch(getAssignedTaskAction(response.data));
                    //utility.showSuccess(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function sendToReviewerData(project_id, time_track_ids, sendToReviewerCallBack, history) {
    return (dispatch) => {
        sendToReviewer(project_id,time_track_ids)
            .then((response) => {
                if (response.data.status == 200) {
                    sendToReviewerCallBack();
                    utility.showSuccess(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function sendToApproverData(project_id,time_track_ids, getTrackData, history) {
    return (dispatch) => {
        sendToApprover(project_id,time_track_ids)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    getTrackData();
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function reviewerApproveTrackingDetailsData(project_id,time_track_ids, callBack, history) {
    return (dispatch) => {
        reviewerApproveTrackingDetails(project_id,time_track_ids)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    callBack();
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function sendBackApproverData(project_id,time_track_ids,callBack, history) {
    return (dispatch) => {
        sendBackApprover(project_id,time_track_ids)
            .then((response) => {
                if (response.data.status == 200) {
                    utility.showSuccess(response.data.msg);
                    callBack();
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateTimeCaptureApproveStatusByReviewerData(track_id, approve_status, page, perPage, orderByField, orderByFieldValue, history) {
    return (dispatch) => {
        updateTimeCaptureApproveStatus(track_id, approve_status)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getApproverTrackingData(page, perPage, orderByField, orderByFieldValue, history));
                    utility.showSuccess(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateMultipleTimeCaptureApproveStatusData(track_id, approve_status, page, perPage, orderByField, orderByFieldValue, history) {
    return (dispatch) => {
        updateMultipleTimeCaptureApproveStatus(track_id, approve_status)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getApproverTrackingData(page, perPage, orderByField, orderByFieldValue, history));
                    utility.showSuccess(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateMultipleTimeCaptureApproveStatusPendingData(track_id, approve_status, page, perPage, orderByField, orderByFieldValue, length, history) {
    return (dispatch) => {
        updateMultipleTimeCaptureApproveStatus(track_id, approve_status)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getPendingTrackingForApproverData(length == 1 ? page - 1 : page, perPage, orderByField, orderByFieldValue, history));
                    utility.showSuccess(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}
export function getReviewerTrackingData(page, perPage, orderByField, orderByFieldValue, history) {
    return (dispatch) => {
        getReviewerTracking(page, perPage, orderByField, orderByFieldValue)
            .then((response) => {
                console.log('response...',response.data)
                if (response.data.status == 200) {
                    dispatch(getReviewerTrackingAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getApproverProjectData(page, perPage, orderByField, orderByFieldValue, history) {
    return (dispatch) => {
        getApproverProject(page, perPage, orderByField, orderByFieldValue)
            .then((response) => {
                console.log('response...',response.data)
                if (response.data.status == 200) {
                    dispatch(getReviewerTrackingAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getApproverTrackingData(page, perPage, orderByField, orderByFieldValue, history) {
    return (dispatch) => {
        getApproverTracking(page, perPage, orderByField, orderByFieldValue)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getReviewerTrackingAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}
export function getProjectApproverTrackingDetails(user_id,project_id, filter_type,month,year,start_date,end_date, history) {
    return (dispatch) => {
        getProjectApproverTracking(user_id,project_id, filter_type,month,year,start_date,end_date)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectApproverTrackingAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getApproverTimeCardDetails(history) {
    return (dispatch) => {
        getApproverTimeCard()
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getProjectApproverTrackingAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getReviewerTimeCardDetails(history) {
    return (dispatch) => {
        getReviewerTimeCard()
            .then((response) => {
                console.log('response..',response.data)
                if (response.data.status == 200) {
                    dispatch(getProjectApproverTrackingAction(response.data));
                } else {
                    utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                //utility.showError(response.data.msg);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function updateTimeCapturePendingStatusByApproverData(track_id, approve_status, page, perPage, orderByField, orderByFieldValue, length, history) {
    return (dispatch) => {
        updateTimeCaptureApproveStatus(track_id, approve_status)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getPendingTrackingForApproverData(length == 1 ? page - 1 : page, perPage, orderByField, orderByFieldValue, history));
                    utility.showSuccess(response.data.msg);
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getPendingTrackingForApproverData(page, perPage, orderByField, orderByFieldValue, history) {
    return (dispatch) => {
        getPendingTrackingForApprover(page, perPage, orderByField, orderByFieldValue)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getPendingTrackingForApproverAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getApprovedTrackingForApproverData(page, perPage, orderByField, orderByFieldValue, history) {
    return (dispatch) => {
        getApprovedTrackingForApprover(page, perPage, orderByField, orderByFieldValue)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getApprovedTrackingForApproverAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getRejectTrackingForApproverData(page, perPage, orderByField, orderByFieldValue, history) {
    return (dispatch) => {
        getRejectTrackingForApprover(page, perPage, orderByField, orderByFieldValue)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getRejectTrackingForApproverAction(response.data));
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getNoteData(project_id, setNotData, history) {
    return (dispatch) => {
        getNote(project_id)
            .then((response) => {
                if (response.data.status == 200) {
                    setNotData(response.data.data);
                }
            })
            .catch((error) => {
                //console.log(error);
                // dispatch(globalLoadingAction(false));
            });
    };
}
export function addNoteData(project_id, note, AddNotData, history) {
    return (dispatch) => {
        addNote(project_id, note)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(addNoteDataAction(project_id, AddNotData, history));
                }
            })
            .catch((error) => {
                //console.log(error);
                // dispatch(globalLoadingAction(false));
            });
    };
}
export function addNoteDataAction(project_id, AddNotData, history) {
    return (dispatch) => {
        getNote(project_id)
            .then((response) => {
                if (response.data.status == 200) {
                    AddNotData(response.data.data);
                }
            })
            .catch((error) => {
                //console.log(error);
                // dispatch(globalLoadingAction(false));
            });
    };
}
export function addTimeAction(data) {
    return {
        type: ADD_TIME_CAPTURE,
        payload: data,
    };
}
export function updateTimeAction(data) {
    return {
        type: UPDATE_TIME_CAPTURE,
        payload: data,
    };
}
export function setSingleDataObject(obj) {
    return {
        type: SET_SINGLE_DATA_OBJECT,
        payload: obj,
    };
}
export function getTimeTrackerAction(data) {
    return {
        type: GET_TIME_CAPTURE,
        payload: data,
    };
}
export function startTimeAction(data) {
    return {
        type: START_TIME_ACTION,
        payload: data,
    };
}
export function stopTimeAction(data) {
    return {
        type: STOP_TIME_ACTION,
        payload: data,
    };
}
export function offsetTimeAction(data) {
    return {
        type: OFFSET_TIME_ACTION,
        payload: data,
    };
}

export function getAssignedTaskAction(data) {
    return {
        type: GET_ASSIGNED_TASK_ACTION,
        payload: data,
    };
}

export function getReviewerTrackingAction(data) {
    return {
        type: GET_TRACKING_DETAILS_ACTION,
        payload: data,
    };
}
export function getPendingTrackingForApproverAction(data) {
    return {
        type: GET_PENDING_TRACKING_FOR_APPROVER_ACTION,
        payload: data,
    };
}
export function getApprovedTrackingForApproverAction(data) {
    return {
        type: GET_APPROVED_TRACKING_FOR_APPROVER_ACTION,
        payload: data,
    };
}
export function getRejectTrackingForApproverAction(data) {
    return {
        type: GET_REJECT_TRACKING_FOR_APPROVER_ACTION,
        payload: data,
    };
}
export function getProjectApproverTrackingAction(data) {
    return {
        type: GET_PROJECT_APPROVER_TRACKING_DETAILS_ACTION,
        payload: data,
    };
}
export function loadingTimeAction(status) {
    return {
        type: LOADING_TIME_CAPTURE,
        payload: status,
    };
}
