import React, { useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getSystemConfigData, addSystemConfigData } from '../../../store/actions/SystemDefaultActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const SystemDefault = (props) => {

    const dispatch = useDispatch();

    const [config, setConfig] = useState([]);
    const [configOption, setConfigOption] = useState([]);
    const [log, setLog] = useState(1);
    const [pages, setPages] = useState(1);
    const [timesheet, setTimesheet] = useState(1);

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getSystemConfigData(props.systemAdmin, props.history));
    }, []);

    useEffect(() => {
        if (props.filterOption) {
            setConfigOption(props.filterOption);
            getConfigData();
        }
    }, [props.filterOption]);


    const submitHandler = () => {
        const array = [...config];
        array[0].allow_user_to_change_opton = log == 1 ? true : false;
        array[0].allow_user_to_change_pagination_option = pages == 1 ? true : false;
        array[1].allow_user_to_change_opton = timesheet == 1 ? true : false;
        dispatch(globalLoadingAction(true));
        dispatch(addSystemConfigData(array, props.history));
    }

    const getConfigData = () => {
        let array = [];
        let obj = {};
        setLog(props.filterOption[0]?.options?.allow_user_to_change ? 1 : 0);
        setPages(props.filterOption[0]?.pagination_option?.allow_user_to_change ? 1 : 0);
        setTimesheet(props.filterOption[1]?.options?.allow_user_to_change ? 1 : 0);
        props.filterOption.forEach((item, index) => {
            if (item.filter_type == 1) {
                item.options.data.forEach((option, j) => {
                    if (option.selected) {
                        obj = { filter_type: item.filter_type, option_id: option.option_id, allow_user_to_change_opton: item.options.allow_user_to_change }
                    }
                });
                item.pagination_option.data.forEach((option, j) => {
                    if (option.selected) {
                        obj = { filter_type: item.filter_type, option_id: option.option_id, pagination_option_id: option.option_id, allow_user_to_change_opton: item.options.allow_user_to_change, allow_user_to_change_pagination_option: item.pagination_option.allow_user_to_change }
                    }
                });
            } else {
                item.options.data.forEach((option, j) => {
                    if (option.selected) {
                        obj = { filter_type: item.filter_type, option_id: option.option_id, allow_user_to_change_opton: item.options.allow_user_to_change }
                    }
                });
            }

            array.push(obj);
        });
        setConfig(array);

    }

    const onConfigCaptureHandler = (val, type, index) => {
        let configArray = [...configOption];
        let array = [...config];
        configArray[type].options.data.forEach((item, i) => {
            if (index == i) {
                configArray[type].options.data[i].selected = true;
            } else {
                configArray[type].options.data[i].selected = false;
            }
        });
        array[type].option_id = val ? parseInt(val) : 0;
        setConfigOption(configArray);
        setConfig(array);

    }

    const onConfigPaginationHandler = (val, type, index) => {
        let configArray = [...configOption];
        let array = [...config];
        configArray[type].pagination_option.data.forEach((item, i) => {
            if (index == i) {
                configArray[type].pagination_option.data[i].selected = true;
            } else {
                configArray[type].pagination_option.data[i].selected = false;
            }
        });
        array[type].pagination_option_id = val ? parseInt(val) : 0;
        setConfigOption(configArray);
        setConfig(array);
    }

    return (
        <>
            {/* {console.log('configOption...', configOption)} */}
            <div className="row">
                <div className="title-body">
                    <h3>System Defaults</h3>
                    <p>Manage Company/Organization Information such as name, type, location address and contacts.</p>
                </div>
                <div className="account-setting-content">
                    <div className="row">
                        <div className="col-xl-11 col-md-12">

                            <div className="account-setting-card">

                                <fieldset className="form-group">
                                    <div className="system-default">
                                        <div className='system-title'>
                                            <h3>{configOption[0]?.filter_name}</h3>
                                        </div>

                                        <div className="system-default-item">

                                            <div className='system-item-group'>
                                                <div className='system-header-title'>
                                                    <p>{configOption[0]?.options?.title}</p>
                                                </div>
                                                <div className='system-title-item'>
                                                    <div className="transaction-check-group">
                                                        {
                                                            configOption[0]?.options?.data?.map((item, index) => {
                                                                return (
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="timeOption"
                                                                            disabled={!configOption[0]?.options?.is_editable}
                                                                            value={item.option_id}
                                                                            checked={item.selected}
                                                                            onChange={(e) => onConfigCaptureHandler(e.target.value, 0, index)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            {item.option_name}
                                                                        </label>
                                                                    </div>

                                                                );
                                                            })
                                                        }

                                                    </div>

                                                </div>

                                                {props.systemAdmin ?
                                                    <div className='system-title-item'>
                                                        <p>Allow user to change</p>
                                                        <div className="transaction-check-group">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="allowRange"
                                                                    disabled={!configOption[0]?.options?.is_editable}
                                                                    value={1}
                                                                    checked={log == 1}
                                                                    onChange={(e) => setLog(e.target.value)}
                                                                />
                                                                <label className="form-check-label">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="allowRange"
                                                                    disabled={!configOption[0]?.options?.is_editable}
                                                                    value={0}
                                                                    checked={log == 0}
                                                                    onChange={(e) => setLog(e.target.value)}
                                                                />
                                                                <label className="form-check-label">
                                                                    NO
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                            <div className='system-item-group'>
                                                <div className='system-header-title'>
                                                    <p>{configOption[0]?.pagination_option?.title}</p>
                                                </div>
                                                <div className='system-title-item'>

                                                    <div className="transaction-check-group">
                                                        {
                                                            configOption[0]?.pagination_option?.data?.map((item, index) => {
                                                                return (
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="pagination"
                                                                            disabled={!configOption[0]?.pagination_option?.is_editable}
                                                                            value={item.option_id}
                                                                            checked={item.selected}
                                                                            onChange={(e) => onConfigPaginationHandler(e.target.value, 0, index)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            {item.option_name}
                                                                        </label>
                                                                    </div>
                                                                );
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                                {props.systemAdmin ?
                                                    <div className='system-title-item'>
                                                        <p>Allow user to change</p>
                                                        <div className="transaction-check-group">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="allowPage"
                                                                    disabled={!configOption[0]?.pagination_option?.is_editable}
                                                                    value={1}
                                                                    checked={pages == 1}
                                                                    onChange={(e) => setPages(e.target.value)}
                                                                />
                                                                <label className="form-check-label">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="allowPage"
                                                                    disabled={!configOption[0]?.pagination_option?.is_editable}
                                                                    value={0}
                                                                    checked={pages == 0}
                                                                    onChange={(e) => setPages(e.target.value)}
                                                                />
                                                                <label className="form-check-label">
                                                                    NO
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>

                                        </div>
                                    </div>
                                </fieldset>

                                <fieldset className="form-group mt-4">
                                    <div className="system-default">
                                        <div className='system-title'>
                                            <h3>{configOption[1]?.filter_name}</h3>
                                        </div>


                                        <div className="system-default-item">
                                            <div className='system-item-group'>
                                                <div className='system-header-title'>
                                                    <p>{configOption[1]?.options?.title}</p>
                                                </div>
                                                <div className='system-title-item'>
                                                    <div className="transaction-check-group me-2">
                                                        {
                                                            configOption[1]?.options?.data?.map((item, index) => {
                                                                return (
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name="timeSheet"
                                                                            disabled={!configOption[1]?.options?.is_editable}
                                                                            value={item.option_id}
                                                                            checked={item.selected}
                                                                            onChange={(e) => onConfigCaptureHandler(e.target.value, 1, index)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            {item.option_name}
                                                                        </label>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {props.systemAdmin ?
                                                    <div className='system-title-item'>
                                                        <p>Allow user to change</p>
                                                        <div className="transaction-check-group">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="allowSheet"
                                                                    disabled={!configOption[1]?.options?.is_editable}
                                                                    value={1}
                                                                    checked={timesheet == 1}
                                                                    onChange={(e) => setTimesheet(e.target.value)}
                                                                />
                                                                <label className="form-check-label">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name="allowSheet"
                                                                    value={0}
                                                                    checked={timesheet == 0}
                                                                    disabled={!configOption[1]?.options?.is_editable}
                                                                />
                                                                <label className="form-check-label">
                                                                    NO
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>

                                    </div>
                                </fieldset>

                                <div className="system-submit-container">
                                    <button type='button' onClick={submitHandler}>Submit</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.systemconfig.screenVisible,
        filterOption: state.systemconfig.options,
        systemAdmin: state.auth.systemAdmin,
    };
};

export default connect(mapStateToProps)(SystemDefault);