import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Table, Modal, Button, Dropdown, Accordion } from "react-bootstrap";
import moment from 'moment';
import swal from "sweetalert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getEmployeeList, getEmployeeListByProjectId } from '../../../store/actions/EmployeeActions';
import { getTimeCardSingleDetails, addOwnTimeSheetData, updateOwnTimeSheetData, removeTimeSheetData, generateTimesheetDetails, postReportClientApprovalData, generatedReportDetails } from '../../../store/actions/TimeCardActions';
import { sendToReviewerData, getNoteData, addNoteData } from '../../../store/actions/TimeCaptureActions';
import { getClientsData } from '../../../store/actions/ClientsActions';
import { getProjectList, getProjectByClientIdList, getProjectActivitiesListData, getAccessableTaskList, getProjectDetailsByIdData, getTaskList } from '../../../store/actions/ProjectActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import { getSystemUserConfigData } from '../../../store/actions/SystemDefaultActions';
import ChatBox from "../Components/ChatBox";
import TimeCardView from "../Components/TimeCardView";

const GeneratedReport = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation();


    const [cardList, setCardList] = useState([]);
    const [addModal, setAddModal] = useState(false);
    //note
    const [message, setMessage] = useState('');
    const [projectId, setProjectId] = useState(null);
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(generatedReportDetails(props.history));
        //dispatch(getClientsData("", "", "", 1, 0, props.history));
        //dispatch(getProjectList("", "", "", 1, 0, props.history));

        // return () => {
        //     dispatch(generateTimesheetAction({}));
        // }

    }, []);


    useEffect(() => {
        console.log('timeCardDetails...', props.timeCardDetails);
        // if (props.timeCardDetails?.data) {
        //     setCardList(props.timeCardDetails.data);
        // }
    }, [props.timeCardDetails]);


    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(projectId, message, AddNotData, props.history));
    }
    const AddNotData = (data) => {
        setNoteData(data);
        setLoading(false);
    }

    const onGetNoteHandler = (task_id) => {
        setProjectId(task_id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(task_id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }

    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }

    const onViewHalder = (data) => {
        if (data) {
            let array = JSON.parse(data);
            setAddModal(true);
            setCardList(array);
        }
    }

    return (
        <>
            <div className="row">

                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>Reporting</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Generated Report</Link>
                        </li>
                    </ul>
                </div>

                <div className="title-body-container">
                    <div className="title-body mb-3">
                        <h3>Generated Report</h3>
                    </div>


                </div>
                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card className='m-0'>
                            <Card.Body>
                                {props.screenVisible ?
                                    <>
                                        {props.timeCardDetails.length > 0 ?
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <strong>Bill Id</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Client Name</strong>
                                                        </th>
                                                        {/* <th>
                                                            <strong>Date</strong>
                                                        </th> */}
                                                        <th>
                                                            <strong>Status</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Actions</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.timeCardDetails.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.bill_id}</td>
                                                                    <td>{item.client_first_name} {item.client_last_name}</td>
                                                                    {/* <td><Moment format="D MMM YYYY" withTitle>{item.date}</Moment></td> */}
                                                                    <td>

                                                                        <div className="d-flex">
                                                                            {(() => {
                                                                                switch (item.status) {
                                                                                    case 1:
                                                                                        return (
                                                                                            <button className="btn btn-warning shadow btn-xs">
                                                                                                Pending
                                                                                            </button>
                                                                                        );
                                                                                    case 2:
                                                                                        return (
                                                                                            <button className="btn btn-success shadow btn-xs">
                                                                                                Approved
                                                                                            </button>
                                                                                        );
                                                                                    case 3:
                                                                                        return (
                                                                                            <button className="btn btn-danger shadow btn-xs">
                                                                                                Reject
                                                                                            </button>
                                                                                        );
                                                                                    default:
                                                                                        return (
                                                                                            <button className="btn btn-secondary shadow btn-xs">
                                                                                                Unknown
                                                                                            </button>
                                                                                        );
                                                                                }
                                                                            })()}
                                                                        </div>

                                                                    </td>
                                                                    <td>
                                                                        <div className="d-flex">
                                                                            <button
                                                                                onClick={() => onViewHalder(item.report_data)}
                                                                                className="btn btn-info shadow btn-xs"
                                                                            >View
                                                                            </button>
                                                                      
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <div className="nodata-found-view">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                <h2>Data not found</h2>
                                                <p>Client data is empty or Try again search</p>
                                                <button type="button"
                                                //onClick={onCleanHandler}
                                                >
                                                    Retry
                                                </button>
                                            </div>
                                        }
                                    </>
                                    : null}
                            </Card.Body>
                        </Card>
                        <div>
                            <p><strong>Note:</strong> Use increments of 0.25 (15 minutes) when recording hours.</p>
                        </div>
                    </Col>
                </div>
            </div>

            <Modal
                className="fade bd-example-modal-sm"
                size="xl"
                show={addModal}
                onHide={() => setAddModal(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Time Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {cardList.length > 0 ?
                        cardList.map((item, index) => {
                            return (
                                <TimeCardView
                                    key={index}
                                    //onNoteHandler={(id) => onGetNoteHandler(id)}
                                    note={false}
                                    details={item} />
                            )
                        })
                        :
                        <div className='text-center mt-3'><h3>No Data Found</h3></div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setAddModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCard.screenVisible,
        showLoading: state.clients.showLoading,
        clientList: state.clients.clientList,
        projectList: state.project.projectList,
        activitiesList: state.project.activitiesList,
        taskList: state.project.taskList,
        countryList: state.country.countryList,
        timeCardDetails: state.timeCard.generatedDetails,
        configDetails: state.systemconfig.configDetails,
        systemAdmin: state.auth.systemAdmin,
        projectDetails: state.project.projectDetails,
        userId: state.auth.userId,
        employeeList: state.employee.projectEmployeeList,
    };
};
export default connect(mapStateToProps)(GeneratedReport);