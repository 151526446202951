import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Row, Col, Card, Table, Modal, Button, Dropdown, Accordion } from "react-bootstrap";
import moment from 'moment';
import Moment from 'react-moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getEmployeeListByProjectId } from '../../../store/actions/EmployeeActions';
import { getReviewerTimeCardSingleDetails } from '../../../store/actions/TimeCardActions';
import { reviewerApproveTrackingDetailsData, getReviewerTimeCardDetails, getReviewerTrackingData, getNoteData, sendToApproverData, addNoteData, updateTimeCaptureApproveStatusByReviewerData } from '../../../store/actions/TimeCaptureActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import ChatBox from "../Components/ChatBox";
import TimeCardView from "../Components/TimeCardView";

const ReviewerTimeCard = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const dateRef = useRef();
    const filterRangeRef = useRef();
    const { state } = useLocation();
    const [projectDetails, setProjectDetails] = useState({});
    const [cardList, setCardList] = useState([]);
    const [projectId, setProjectId] = useState(null);
    const [filterType, setFilterType] = useState(2);
    const [startDate, setStartDate] = useState('');

    //Note
    const [message, setMessage] = useState('');
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [filterUser, setFilterUser] = useState('');
    const [filterStartDateRange, setFilterStartDateRange] = useState('');
    const [filterEndDateRange, setFilterEndDateRange] = useState('');

    useEffect(() => {
        if (state._id) {
            dispatch(getEmployeeListByProjectId(state._id, props.history));
        }
    }, [state._id]);

    useEffect(() => {
        if (filterUser) {
            props.employeeList.forEach(element => {
                if (filterUser == element._id) {
                    setUserDetails(element);
                }
            });
        } else {
            setUserDetails(null);
        }
    }, [filterUser]);

    useEffect(() => {
        if (filterType === 1 || filterType === 2) {
            let month = startDate ? moment(startDate).format('M') : moment().format('M');
            let year = startDate ? moment(startDate).format('YYYY') : moment().format('YYYY');

            dispatch(globalLoadingAction(true));
            dispatch(getReviewerTimeCardSingleDetails(
                filterUser,
                state._id,
                filterType,
                filterType === 1 ? parseInt(month) : '',
                filterType === 1 ? parseInt(year) : '',
                '', '',
                props.history));
            setFilterStartDateRange('');
            setFilterEndDateRange('');
        }
        //dispatch(getReviewerTimeCardDetails(props.history));
    }, [filterType, startDate]);

    const onFilterTypeHandler = (val) => {
        setFilterType(val);
    }

    useEffect(() => {
        if (props.approverCardDetails?.data) {
            setCardList(props.approverCardDetails.data);
        }
        // if (props.approverCardDetails?.project_details) {
        //     setProjectDetails(props.approverCardDetails.project_details);
        // }
    }, [props.approverCardDetails]);

    const onGetNoteHandler = (id) => {
        setProjectId(id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }
    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }
    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(projectId, message, AddNotData, props.history));
    }
    const AddNotData = (data) => {
        setNoteData(data);
        setLoading(false);
    }

    const onChangeDateFilterHandler = (date) => {
        setStartDate(date);
        if (date) {
            setFilterType(1);
        }
    }

    const onDateRangeFilterHandler = (dates) => {
        const [start, end] = dates;
        setFilterStartDateRange(start);
        setFilterEndDateRange(end);
        if (start && end) {
            dispatch(globalLoadingAction(true));
            dispatch(getReviewerTimeCardSingleDetails(
                filterUser,
                state._id,
                3, '', '',
                moment(start).format('YYYY-MM-DD'),
                moment(end).format('YYYY-MM-DD'),
                props.history
            ));
            setStartDate('');
            setFilterType(3);
        }
    }

    // const onAssignHandler = (item) => {
    //     let track_details = item.time_track_details;
    //     let array = [];
    //     track_details.forEach(element => {
    //         if (element.time_track_id) {
    //             array.push(element.time_track_id)
    //         }
    //     });
    //     dispatch(globalLoadingAction(true));
    //     dispatch(reviewerApproveTrackingDetailsData(state.id, array, getTrackData, props.history));
    // };

    const getTrackData = () => {
        // dispatch(getReviewerTimeCardSingleDetails(state.id, props.history));
        //dispatch(getReviewerTimeCardDetails(props.history));
    }

    const onFilterUserHandler = (val) => {
        setFilterUser(val);
        let month = startDate ? moment(startDate).format('M') : moment().format('M');
        let year = startDate ? moment(startDate).format('YYYY') : moment().format('YYYY');
        dispatch(globalLoadingAction(true));
        dispatch(getReviewerTimeCardSingleDetails(
            val,
            state._id,
            filterType,
            filterType === 1 ? parseInt(month) : '',
            filterType === 1 ? parseInt(year) : '',
            filterType === 3 && filterStartDateRange ? moment(filterStartDateRange).format('YYYY-MM-DD') : '',
            filterType === 3 && filterEndDateRange ? moment(filterEndDateRange).format('YYYY-MM-DD') : '',
            props.history
        ));

    }


    return (
        <>
            <div className="row">

                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"/task-details"}>My Reviews</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"/time-cart"}>Timesheet</Link>
                        </li>
                    </ul>
                </div>
                <div className="title-body-container">
                    <div className="title-body">
                        <h3>{filterType == 1 ? 'Monthly' : filterType == 2 ? 'Weekly' : filterType == 3 ? 'Date wise' : null} Timesheet</h3>
                    </div>
                    <div className="title-filter-container">
                        <div className="button-filter">
                            <button className="time-sheet-filter-btn" style={{ backgroundColor: filterType == 2 ? '#332d28' : '#7e7c7a' }} onClick={() => onFilterTypeHandler(2)} type='button'>Weekly</button>
                            <div className="time-sheet-filter-date-view">
                                <DatePicker
                                    ref={dateRef}
                                    selected={startDate}
                                    showMonthYearPicker
                                    className="d-none"
                                    onChange={(date) => onChangeDateFilterHandler(date)}
                                    shouldCloseOnSelect={true}
                                    disabledKeyboardNavigation
                                />

                                <button
                                    type="button"
                                    className="time-sheet-filter-date-btn"
                                    style={{ backgroundColor: filterType === 1 ? '#332d28' : '#7e7c7a' }}
                                    onClick={() => dateRef.current.setOpen(true)}
                                >
                                    {startDate && filterType === 1 ? `${moment(startDate).format('MMMM YYYY')}` : 'Monthly'}
                                </button>

                            </div>
                            <div className="time-sheet-filter-date-view">
                                <DatePicker
                                    ref={filterRangeRef}
                                    selected={filterStartDateRange}
                                    className="d-none"
                                    selectsRange
                                    startDate={filterStartDateRange}
                                    endDate={filterEndDateRange}
                                    onChange={(dates) => onDateRangeFilterHandler(dates)}
                                />
                                <button
                                    type="button"
                                    className="time-sheet-filter-date-btn"
                                    style={{ backgroundColor: filterType === 3 ? '#332d28' : '#7e7c7a' }}
                                    onClick={() => filterRangeRef.current.setOpen(true)}
                                >
                                    {filterType === 3 && filterStartDateRange && filterEndDateRange
                                        ? `${moment(filterStartDateRange).format('DD/MM/YY')} - ${moment(filterEndDateRange).format('DD/MM/YY')}`
                                        : 'Date Range'}
                                </button>
                            </div>

                            <div className="form-group card-filter-select me-2">
                                <div className="select-drop-down">
                                    <span><i class="fa fa-angle-down"></i></span>
                                    <select
                                        defaultValue={"option"}
                                        className="form-control select-control"
                                        value={filterUser}
                                        onChange={(e) => onFilterUserHandler(e.target.value)}
                                    >
                                        <option value="">All Users</option>
                                        {props.employeeList.map((item, index) => {
                                            // Conditionally rendering the option to exclude the current user's ID
                                            return (
                                                props.userId !== item._id && (
                                                    <option key={index} value={item._id}>
                                                        {item.first_name} {item.last_name}
                                                    </option>
                                                )
                                            );
                                        })}
                                    </select>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>

                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card className='m-0'>

                            <Card.Body className="pt-3">

                                {userDetails ?
                                    <div className="card-detail-header">

                                        <div className="card-detail-left">
                                            <div>
                                                <h4 className='mb-2'><strong>Employee ID:</strong> {userDetails?.employee_id}</h4>
                                            </div>
                                            <div>
                                                <h4><strong>Employee Email:</strong> {userDetails?.email}</h4>
                                            </div>
                                        </div>
                                        <div className="card-detail-right">
                                            <div>
                                                <label className='mb-2'><strong>Employee Name:</strong> {userDetails?.first_name} {userDetails?.last_name}</label>
                                            </div>

                                        </div>
                                    </div> : null}
                                <div>
                                    {cardList.length > 0 ?
                                        cardList.map((item, index) => {
                                            return (
                                                <TimeCardView
                                                    key={index}
                                                    type={1}
                                                    onNoteHandler={(id) => onGetNoteHandler(id)}
                                                    details={item} />
                                            )
                                        })
                                        :
                                        <div className='text-center mt-3'><h3>No Data Found</h3></div>}
                                </div>
                            </Card.Body>
                        </Card>
                        <div>
                            <p><strong>Note:</strong> Use increments of 0.25 (15 minutes) when recording hours.</p>
                        </div>
                    </Col>
                </div>
            </div>
            <ChatBox
                show={isNote}
                data={noteData}
                value={message}
                onChangeHandler={(val) => setMessage(val)}
                onSubmitHandler={() => onSubmitMessageHandler()}
                closeHandler={closeHandler}
                loading={loading}
                currentUser={props.userId}
            />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCapture.screenVisible,
        showLoading: state.timeCapture.showLoading,
        list: state.timeCapture.approvePendingList,
        totalPage: state.timeCapture.approverPendingPage,
        totalRecord: state.timeCapture.approverPendingRecord,
        // approverCardDetails: state.timeCapture.approverCardDetails,
        approverCardDetails: state.timeCard.details,
        userId: state.auth.userId,
        employeeList: state.employee.projectEmployeeList,
    };
};
export default connect(mapStateToProps)(ReviewerTimeCard);