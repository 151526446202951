import {
    LOADING_EMPLOYEE,
    ADD_EMPLOYEE,
    GET_KEEPER_LIST,
    GET_EMPLOYEE_LIST,
    UPDATE_EMPLOYEE_STATUS,
    GET_APPROVER_LIST,
    GET_REVIEWER_LIST,
    REMOVE_EMPLOYEE,
    GET_GROUP_KEEPER_LIST,
    GET_REPORT_TO_EMPLOYEE_LIST,
    GET_EMPLOYEE_LIST_BY_PROJECT_ID
} from '../constants';

const initialState = {
    employeeList: [],
    projectEmployeeList:[],
    reportEmployeeList: [],
    approverList: [],
    reviewerList: [],
    keeperList: [],
    groupKeeperList: [],
    totalPage: 0,
    totalRecord: 0,
    screenVisible: false,
    showLoading: false,
};

export function EmployeeReducer(state = initialState, action) {

    if (action.type === GET_EMPLOYEE_LIST) {
        return {
            ...state,
            totalPage: action.payload.total_page,
            totalRecord: action.payload.total_record,
            employeeList: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }

    if (action.type === GET_EMPLOYEE_LIST_BY_PROJECT_ID) {
        return {
            ...state,
            projectEmployeeList: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }

    if (action.type === GET_REPORT_TO_EMPLOYEE_LIST) {
        let array = action.payload.data.map(element => ({
            id: element._id,
            name: element.first_name + ' ' + element.last_name,
            email: element.email,
            email_id: element.employee_id,
            phone: element.phone_no
        }));
        return {
            ...state,
            reportEmployeeList: array,
        };
    }

    if (action.type === UPDATE_EMPLOYEE_STATUS) {
        const list = [...state.employeeList];
        const index = list.findIndex((item) => item._id === action.payload._id);
        list[index].status = action.payload.status === 1 ? 0 : 1;
        return {
            ...state,
            employeeList: list,
            showLoading: false,
        };
    }

    if (action.type === REMOVE_EMPLOYEE) {
        const list = [...state.employeeList];
        const index = list.findIndex((item) => item._id === action.payload);
        list.splice(index, 1);
        return {
            ...state,
            employeeList: list,
            showLoading: false,
        };
    }

    if (action.type === GET_APPROVER_LIST) {
        return {
            ...state,
            approverList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_REVIEWER_LIST) {
        return {
            ...state,
            reviewerList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_KEEPER_LIST) {
        return {
            ...state,
            keeperList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_GROUP_KEEPER_LIST) {
        return {
            ...state,
            groupKeeperList: action.payload,
            showLoading: false,
        };
    }

    if (action.type === LOADING_EMPLOYEE) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


