import React, { useContext, Fragment, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    Dropdown,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import ChatBox from "../Components/ChatBox";
import { getEmployeeListByProjectId } from '../../../store/actions/EmployeeActions';
import { getProjectApproverTrackingDetails, getApproverTimeCardDetails, sendBackApproverData, sendToApproverData, reviewerApproveTrackingDetailsData, updateMultipleTimeCaptureApproveStatusPendingData, getPendingTrackingForApproverData, getNoteData, addNoteData, updateTimeCaptureApproveStatusByReviewerData } from '../../../store/actions/TimeCaptureActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';
import TimeCardView from "../Components/TimeCardView";

const PendingTab = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const dateRef = useRef();
    const filterRangeRef = useRef();

    const [projectDetails, setProjectDetails] = useState({});
    const [cardList, setCardList] = useState([]);
    //Node
    const [message, setMessage] = useState('');
    const [noteData, setNoteData] = useState([]);
    const [isNote, setIsNote] = useState(false);
    const [projectId, setProjectId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [trackArry, setTrackArry] = useState([]);
    const [filterType, setFilterType] = useState(2);
    const [startDate, setStartDate] = useState('');
    const [userDetails, setUserDetails] = useState(null);
    const [filterUser, setFilterUser] = useState('');
    const [filterStartDateRange, setFilterStartDateRange] = useState('');
    const [filterEndDateRange, setFilterEndDateRange] = useState('');

    useEffect(() => {
        dispatch(getEmployeeListByProjectId(props.projectId._id, props.history));
    }, []);

    useEffect(() => {
        if (filterUser) {
            props.employeeList.forEach(element => {
                if (filterUser == element._id) {
                    setUserDetails(element);
                }
            });
        } else {
            setUserDetails(null);
        }
    }, [filterUser]);

    useEffect(() => {
        if (filterType === 1 || filterType === 2) {
            let month = startDate ? moment(startDate).format('M') : moment().format('M');
            let year = startDate ? moment(startDate).format('YYYY') : moment().format('YYYY');

            dispatch(globalLoadingAction(true));
            dispatch(getProjectApproverTrackingDetails(
                filterUser,
                props.projectId._id,
                filterType,
                filterType === 1 ? parseInt(month) : '',
                filterType === 1 ? parseInt(year) : '',
                '', '',
                props.history));
            setFilterStartDateRange('');
            setFilterEndDateRange('');
        }
        //dispatch(getApproverTimeCardDetails(props.history));
    }, [filterType, startDate]);

    useEffect(() => {
        if (props.approverCardDetails?.data) {
            setCardList(props.approverCardDetails.data);
        }
        if (props.approverCardDetails?.project_details) {
            setProjectDetails(props.approverCardDetails.project_details);
        }
    }, [props.approverCardDetails]);

    const onFilterTypeHandler = (val) => {
        setFilterType(val);
    }

    const closeHandler = () => {
        setIsNote(false);
        setNoteData([]);
    }

    const onSubmitMessageHandler = () => {
        setMessage("");
        setLoading(true);
        dispatch(addNoteData(projectId, message, AddNotData, props.history));
    }
    const AddNotData = (data) => {
        if (trackArry.length > 0) {
            setLoading(false);
            setIsNote(false);
            onSubmitStatus();
        } else {
            setNoteData(data);
            setLoading(false);
        }
        setNoteData(data);
        setLoading(false);
    }

    const onAssingHandler = (item) => {
        setProjectId(item.project_id);
        setTrackArry(item.data);
        onGetNoteHandler(item);
    }

    const onGetNoteHandler = (id) => {
        setProjectId(id);
        dispatch(globalLoadingAction(true));
        dispatch(getNoteData(id, setNotData, props.history));
    }

    const setNotData = (data) => {
        dispatch(globalLoadingAction(false));
        setNoteData(data);
        setIsNote(true);
    }

    const onSubmitStatus = () => {
        let array = [];
        trackArry.forEach(element => {
            if (element.time_track_id) {
                array.push(element.time_track_id)
            }
        });
        dispatch(globalLoadingAction(true));
        dispatch(reviewerApproveTrackingDetailsData(projectId, array, getTrackData, props.history));
    }

    const getTrackData = () => {
        //dispatch(getProjectApproverTrackingDetails(props.projectId, 0, props.history));
        //dispatch(getApproverTimeCardDetails(props.history));
        setTrackArry([]);
    }

    const onChangeDateFilterHandler = (date) => {
        setStartDate(date);
        if (date) {
            setFilterType(1);
        }
    }

    const onDateRangeFilterHandler = (dates) => {
        const [start, end] = dates;
        setFilterStartDateRange(start);
        setFilterEndDateRange(end);
        if (start && end) {
            dispatch(globalLoadingAction(true));
            dispatch(getProjectApproverTrackingDetails(
                filterUser,
                props.projectId._id, 3,
                '', '',
                moment(start).format('YYYY-MM-DD'),
                moment(end).format('YYYY-MM-DD'),
                props.history
            ));
            setStartDate('');
            setFilterType(3);
        }
    }

    const onFilterUserHandler = (val) => {
        setFilterUser(val);
        let month = startDate ? moment(startDate).format('M') : moment().format('M');
        let year = startDate ? moment(startDate).format('YYYY') : moment().format('YYYY');
        dispatch(globalLoadingAction(true));
        dispatch(getProjectApproverTrackingDetails(
            val,
            props.projectId._id,
            filterType,
            filterType === 1 ? parseInt(month) : '',
            filterType === 1 ? parseInt(year) : '',
            filterType === 3 && filterStartDateRange ? moment(filterStartDateRange).format('YYYY-MM-DD') : '',
            filterType === 3 && filterEndDateRange ? moment(filterEndDateRange).format('YYYY-MM-DD') : '',
            props.history
        ));

    }

    return (
        <Fragment>
            
            <div className="title-body-container">
                <div className="title-body">
                    <h3>{filterType == 1 ? 'Monthly' : filterType == 2 ? 'Weekly' : filterType == 3 ? 'Date wise' : null} Timesheet</h3>
                </div>
                <div className="title-filter-container">
                    <div className="button-filter">
                        <button className="time-sheet-filter-btn" style={{ backgroundColor: filterType == 2 ? '#332d28' : '#7e7c7a' }} onClick={() => onFilterTypeHandler(2)} type='button'>Weekly</button>
                        <div className="time-sheet-filter-date-view">
                            <DatePicker
                                ref={dateRef}
                                selected={startDate}
                                showMonthYearPicker
                                className="d-none"
                                onChange={(date) => onChangeDateFilterHandler(date)}
                                shouldCloseOnSelect={true}
                                disabledKeyboardNavigation
                            />

                            <button
                                type="button"
                                className="time-sheet-filter-date-btn"
                                style={{ backgroundColor: filterType === 1 ? '#332d28' : '#7e7c7a' }}
                                onClick={() => dateRef.current.setOpen(true)}
                            >
                                {startDate && filterType === 1 ? `${moment(startDate).format('MMMM YYYY')}` : 'Monthly'}
                            </button>
                        </div>

                        <div className="time-sheet-filter-date-view">
                            <DatePicker
                                ref={filterRangeRef}
                                selected={filterStartDateRange}
                                className="d-none"
                                selectsRange
                                startDate={filterStartDateRange}
                                endDate={filterEndDateRange}
                                onChange={(dates) => onDateRangeFilterHandler(dates)}
                            />
                            <button
                                type="button"
                                className="time-sheet-filter-date-btn"
                                style={{ backgroundColor: filterType === 3 ? '#332d28' : '#7e7c7a' }}
                                onClick={() => filterRangeRef.current.setOpen(true)}
                            >
                                {filterType === 3 && filterStartDateRange && filterEndDateRange
                                    ? `${moment(filterStartDateRange).format('DD/MM/YY')} - ${moment(filterEndDateRange).format('DD/MM/YY')}`
                                    : 'Date Range'}
                            </button>
                        </div>

                        <div className="form-group card-filter-select me-2">
                            <div className="select-drop-down">
                                <span><i class="fa fa-angle-down"></i></span>
                                <select
                                    defaultValue={"option"}
                                    className="form-control select-control"
                                    value={filterUser}
                                    onChange={(e) => onFilterUserHandler(e.target.value)}>
                                    <option value="">All User</option>
                                    {props.employeeList.map((item, index) => {
                                        // Conditionally rendering the option to exclude the current user's ID
                                        return (
                                            props.userId !== item._id && (
                                                <option key={index} value={item._id}>
                                                    {item.first_name} {item.last_name}
                                                </option>
                                            )
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
            <div className="account-setting-content">
                <Col lg={12}>
                    <Card className='m-0'>
                        <Card.Body className="pt-3">
                            {userDetails ?
                                <div className="card-detail-header">

                                    <div className="card-detail-left">
                                        <div>
                                            <h4 className='mb-2'><strong>Employee ID:</strong> {userDetails?.employee_id}</h4>
                                        </div>
                                        <div>
                                            <h4 className='mb-'><strong>Employee Email:</strong> {userDetails?.email}</h4>
                                        </div>
                                    </div>
                                    <div className="card-detail-right">
                                        <div>
                                            <label className='mb-2'><strong>Employee Name:</strong> {userDetails?.first_name} {userDetails?.last_name}</label>
                                        </div>

                                    </div>
                                </div> : null}
                            
                                {cardList.length > 0 ?
                                    cardList.map((item, index) => {
                                        return (
                                            <TimeCardView
                                                key={index}
                                                type={1}
                                                onNoteHandler={(id) => onGetNoteHandler(id)}
                                                details={item} />
                                        )
                                    })
                                    :
                                    <div className='text-center mt-3'><h3>No Data Found</h3></div>}
                           
                        </Card.Body>
                    </Card>
                </Col>
            </div>
            <ChatBox
                show={isNote}
                data={noteData}
                value={message}
                onChangeHandler={(val) => setMessage(val)}
                onSubmitHandler={() => onSubmitMessageHandler()}
                closeHandler={closeHandler}
                loading={loading}
                currentUser={props.userId}
            />
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.timeCapture.screenVisible,
        showLoading: state.timeCapture.showLoading,
        list: state.timeCapture.approvePendingList,
        totalPage: state.timeCapture.approverPendingPage,
        totalRecord: state.timeCapture.approverPendingRecord,
        approverCardDetails: state.timeCapture.approverCardDetails,
        employeeList: state.employee.projectEmployeeList,
        userId: state.auth.userId,
    };
};
export default connect(mapStateToProps)(PendingTab);