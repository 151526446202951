import {
    LOADING_TIME_CARD,
    GET_TIME_CARD_LIST,
    GET_TIME_CARD_SINGLE_DETAILS,
    GET_GENERATE_TIME_CARD_DETAILS,
    GET_MANUAL_TIMESHEET,
    GET_GENERATED_REPORTS_DETAILS
} from '../constants';

const initialState = {
    list: [],
    details: {},
    generateDetails: {},
    generatedDetails: [],
    totalPage: 0,
    totalRecord: 0,
    screenVisible: false,
    showLoading: false,
};

export function TimeCardReducer(state = initialState, action) {

    if (action.type === GET_TIME_CARD_LIST) {
        return {
            ...state,
            totalPage: action.payload.total_page,
            totalRecord: action.payload.total_record,
            list: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }

    if (action.type === GET_TIME_CARD_SINGLE_DETAILS) {
        return {
            ...state,
            details: action.payload,
            screenVisible: true,
        };
    }

    if (action.type === GET_GENERATE_TIME_CARD_DETAILS) {
        return {
            ...state,
            generateDetails: action.payload,
            screenVisible: true,
        };
    }

    if (action.type === GET_GENERATED_REPORTS_DETAILS) {
        return {
            ...state,
            generatedDetails: action.payload.data,
            screenVisible: true,
        };
    }


    if (action.type === GET_MANUAL_TIMESHEET) {
        return {
            ...state,
            totalPage: action.payload.total_page,
            totalRecord: action.payload.total_record,
            list: action.payload.data,
            screenVisible: true,
            showLoading: false,
        };
    }

    if (action.type === LOADING_TIME_CARD) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    return state;
}


