import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function addProject(project_unique_id, title, description, client_id, group_id, start_date, end_date, project_type, rate, billable) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        project_unique_id,
        title,
        description,
        client_id,
        group_id,
        start_date,
        end_date,
        project_type,
        rate,
        billable
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_PROJECT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateProject(project_id, project_unique_id, title, description, client_id, group_id, start_date, end_date, status, project_type, rate, billable) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        project_id,
        project_unique_id,
        title,
        description,
        client_id,
        group_id,
        start_date,
        end_date,
        status,
        project_type,
        rate,
        billable
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_PROJECT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getProject(searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let company_id = JSON.parse(getData).company_id;

    const postData = {
        company_id,
        searchString,
        orderByField,
        orderByFieldValue,
        page_no,
        per_page,
        system_admin
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_PROJECT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getProjectByClientId(client_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        client_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_PROJECT_LIST_BY_CLIENT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getProjectDetails(project_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_PROJECT_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function updateProjectStatus(project_id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_PROJECT_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function removeProject(project_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_PROJECT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function addActivities(project_id, activity_unique_id, title, description, start_date, end_date) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        activity_unique_id,
        title,
        description,
        start_date,
        end_date
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_ACTIVITIES_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateActivities(activity_id, activity_unique_id, title, description, start_date, end_date, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        activity_id,
        activity_unique_id,
        title,
        description,
        start_date,
        end_date,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_ACTIVITIES_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getProjectActivitiesList(project_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        searchString,
        orderByField,
        orderByFieldValue,
        page_no,
        per_page,
        system_admin,
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.PROJECT_ACTIVITIES_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function checkActivity(project_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.CHECK_PROJECT_ACTIVITIES_EXISTANCE_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateProjectActiviesStatus(activity_id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        activity_id,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_PROJECT_ACTIVITIES_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function removeProjectActivities(activity_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        activity_id,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_PROJECT_ACTIVITIES_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function addTask(project_id, activity_id, task_unique_id, title, description, activity, time_keepers, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        activity_id,
        task_unique_id,
        title,
        description,
        activity,
        time_keepers,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.ADD_TASK_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateTask(task_id, task_unique_id, title, description, activity, time_keepers, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        task_id,
        task_unique_id,
        title,
        description,
        activity,
        time_keepers,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_TASK_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function taskList(activity_id, searchString, orderByField, orderByFieldValue, page_no, per_page, system_admin) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        activity_id,
        searchString,
        orderByField,
        orderByFieldValue,
        page_no,
        per_page,
        system_admin
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.TASK_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getAccessableTask(activity_id, user_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        activity_id,
        user_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.ACCESSABLE_TASK_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function checkUsedTask(task_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        task_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.USED_TASK_DATA_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function updateTaskStatus(task_id, status) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        task_id,
        status
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.UPDATE_TASK_STATUS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function removeTask(task_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        task_id,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_TASK__API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function downloadProjectRecord(projectIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        projectIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.DOWNLOAD_PROJECTS_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function downloadProjectActivitiesRecord(activityIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        activityIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.DOWNLOAD_ACTIVITIES_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function downloadProjectTaskRecord(taskIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        taskIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.DOWNLOAD_TASKS_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeMultipleProject(projectIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        projectIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_MULTIPLE_PROJECTS_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeMultipleActivies(activityIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        activityIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_MULTIPLE_ACTIVITIES_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function removeMultipleTaska(taskIds) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        taskIds,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.REMOVE_MULTIPLE_TASKS_RECORD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function getTaskDetailsById(task_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        task_id
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_TASK_DETAILS_BY_ID_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getProjectDetailsById(project_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_PROJECT_DETAILS_BY_ID_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getClientProject(searchString, orderByField, orderByFieldValue, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        searchString,
        orderByField,
        orderByFieldValue,
        page_no,
        per_page
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_CLIENT_PROJECT_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function getClientActivitiesList(project_id, searchString, orderByField, orderByFieldValue, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        project_id,
        searchString,
        orderByField,
        orderByFieldValue,
        page_no,
        per_page
    };

    return axios({
        url: GlobalConfigs.API_URL + Apis.CLIENT_ACTIVITIES_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function clientTaskList(activity_id, searchString, orderByField, orderByFieldValue, page_no, per_page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        activity_id,
        searchString,
        orderByField,
        orderByFieldValue,
        page_no,
        per_page
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.CLIENT_TASK_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

