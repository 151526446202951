import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useHistory, useLocation } from 'react-router-dom';
import swal from "sweetalert";
import Select from 'react-select';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import {
    Row,
    Col,
    Card,
    Table,
    Modal,
    Button,
    ProgressBar,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import AddProjectTask from '../Components/AddProjectTask';
import { addTaskData,getClientTaskList, getTaskList, updateTaskData, updateTaskStatusData, removeTaskData, downloadProjectTaskData, removeMultipleTaskaData,checkUsedTaskData } from '../../../store/actions/ProjectActions';
import { getKeeperTeamListData } from '../../../store/actions/EmployeeActions';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const ClientTask = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation();

    let errorsObj = { title: '', desc: '', activity: '', status: '', timeKeeper: '' };
    const [errors, setErrors] = useState(errorsObj);

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [activity, setActivity] = useState('');
    const [timeKeeper, setTimeKeeper] = useState([]);
    const [status, setStatus] = useState('');
    const [taskAction, setTaskAction] = useState(1);
    const [taskId, setTaskId] = useState('');
    const [optionsArray, setOptionsArray] = useState([]);
    const [taskModal, setTaskModal] = useState(false);
    const [viewProjectModal, setViewProjectModal] = useState(false);
    const [details, setDetails] = useState({});

    const [isDisabled, setIsDisabled] = useState(true);
    const [checkArray, setCheckArray] = useState([]);

    const chackbox = document.querySelectorAll(".checkbox-select input");
    const motherChackBox = document.querySelector("#checkAll");

    //filter
    const [searchText, setSearchText] = useState('');
    const [sordBy, setSordBy] = useState('');
    const [sort, setSort] = useState(null);
    const [pageNo, setPageNo] = useState(1);
    const [perPage, setPerPage] = useState(10);


    useEffect(() => {
        dispatch(globalLoadingAction(true));
        dispatch(getClientTaskList('', '', '', '', 1, perPage, props.history));
        // dispatch(getKeeperTeamListData(state.group_id, props.history));
    }, []);

    useEffect(() => {
        let array = [];
        let obj = {};
        props.groupKeeperList.forEach((emp, index) => {
            obj = { value: emp._id, label: emp.first_name };
            array.push(obj);
        });
        setOptionsArray(array);
    }, [props.groupKeeperList]);

    const onSubmitTaskHandler = (data) => {
        dispatch(globalLoadingAction(true));
        if (taskAction == 1) {
            dispatch(addTaskData(data.project, data.project_activity,data.unique_id, data.title, data.description,
                data.activity, data.timeKeeper, 1, perPage, 1,props.systemAdmin, props.history));
            setSearchText('');
            setSordBy('');
            setSort(null);
            setPageNo(1);
        } else {
            dispatch(updateTaskData(data.taskId,data.unique_id, data.title,
                data.description, data.activity,
                data.timeKeeper,
                data.status,
                '',
                searchText,
                sordBy,
                sort === null ? sort ? 'asc' : 'desc' : '',
                pageNo,
                perPage,
                props.systemAdmin,
                props.history));
        }
        setTaskModal(false);

    }

    const onAddProjectHandler = () => {
        setTaskAction(1);
        setTaskModal(true);
        setIsDisabled(true);
        setCheckArray([]);
        chackbox.forEach((val, index) => {
            chackbox[index].checked = false;
        });
        motherChackBox.checked = false;
    }

    const onEditProjectHandler = (item) => {
        setDetails(item);
        setTaskAction(2);
        setTaskModal(true);
        setIsDisabled(true);
        setCheckArray([]);
        chackbox.forEach((val, index) => {
            chackbox[index].checked = false;
        });
        motherChackBox.checked = false;
    }

    const onViewProjectHandler = (item) => {
        setTitle(item.title);
        setDescription(item.description);
        setStatus(item.status);
        setActivity(item.activity);
        setViewProjectModal(true);
    }

    const onStatusHandler = (item) => {
        dispatch(globalLoadingAction(true));
        dispatch(updateTaskStatusData(item));
    }


    const onDeleteHandler = (id) => {
        swal({
            title: "Are you sure?",
            text:
                "You want to remove this item !",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                if (props.taskList.length == 1) {
                    dispatch(removeTaskData(id, '', searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo - 1, perPage,props.systemAdmin, props.history));
                    setPageNo(pageNo - 1);
                } else {
                    dispatch(removeTaskData(id, '', searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo, perPage,props.systemAdmin, props.history));
                }
            }
        })
    }

    const onMultipleRemoveHandler = () => {
        swal({
            title: "Are you sure?",
            text: checkArray.length > 1 ? "You want to remove items!" : "You want to remove item!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(globalLoadingAction(true));
                if (props.taskList.length == checkArray.length) {
                    dispatch(removeMultipleTaskaData(checkArray, '', searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo - 1, perPage,props.systemAdmin, props.history));
                    setPageNo(pageNo - 1);
                } else {
                    dispatch(removeMultipleTaskaData(checkArray, '', searchText, sordBy, sort === null ? sort ? 'asc' : 'desc' : '', pageNo, perPage,props.systemAdmin, props.history));
                }
                setIsDisabled(true);
                setCheckArray([]);
                chackbox.forEach((val, index) => {
                    chackbox[index].checked = false;
                });
                motherChackBox.checked = false;
            }
        })
    }

    const chackboxFun = (element, item) => {

        let array = [...checkArray];
        if (item === 0) {
            if (motherChackBox.checked == true) {
                props.taskList.forEach((val, index) => {
                    array.push(val._id);
                    document.querySelector(`#check${index}`).checked = true;
                });
                setIsDisabled(false);
            } else {
                props.taskList.forEach((val, index) => {
                    array = [];
                    document.querySelector(`#check${index}`).checked = false;
                });
                setIsDisabled(true);
            }

        } else {

            if (element.target.checked == true) {
                for (let x = 0; chackbox.length > x; x++) {
                    if (document.querySelector(`#check${x}`).checked == false) {
                        motherChackBox.checked = false;
                        break;
                    } else {
                        motherChackBox.checked = true;
                        setIsDisabled(false);
                    }
                }
                setIsDisabled(false);
                array.push(item._id);
            } else {
                const index = array.findIndex((val) => val === item.id);
                array.splice(index, 1);
                motherChackBox.checked = false;
                for (let x = 0; chackbox.length > x; x++) {
                    if (document.querySelector(`#check${x}`).checked == true) {
                        setIsDisabled(false);
                        break;
                    } else {
                        setIsDisabled(true);
                    }
                }
            }
        }
        setCheckArray(array);
    };

    const onDownloadHandler = () => {
        dispatch(globalLoadingAction(true));
        dispatch(downloadProjectTaskData(checkArray, props.history));
        setIsDisabled(true);
        setCheckArray([]);
        chackbox.forEach((val, index) => {
            chackbox[index].checked = false;
        });
        motherChackBox.checked = false;
    }

    const onCleanHandler = () => {
        setSearchText('');
        setPageNo(1);
        setSort(null);
        setSordBy('');
        dispatch(globalLoadingAction(true));
        dispatch(getClientTaskList('', '', '', '', 1, perPage, props.history));
    }

    const onSearchHandler = (val) => {
        setSearchText(val);
        setSort(null);
        setSordBy('');
        const timerId = setTimeout(() => {
            dispatch(globalLoadingAction(true));
            dispatch(getClientTaskList('', val, '', '', 1, perPage, props.history));
        }, 1000);
        return () => clearTimeout(timerId); // Cleanup on component unmount or re-render
    }

    const handlePageClick = (data) => {
        setPageNo(data.selected + 1);
        dispatch(globalLoadingAction(true));
        dispatch(getClientTaskList('', searchText, '', '', data.selected + 1, perPage, props.history));
        setSort(null);
        setSordBy('');
    };

    const onSordByHandler = (val) => {
        setSordBy(val);
        setSort(null);
    }

    const onSordHandler = () => {
        if (sordBy) {
            setSort(!sort);
            dispatch(globalLoadingAction(true));
            dispatch(getClientTaskList('', searchText, sordBy, !sort ? 'asc' : 'desc', 1, perPage, props.history));
        }
    }

    const onTimeCaptureHandler = (items) => {
        dispatch(globalLoadingAction(true));
        dispatch(checkUsedTaskData(items, props.history));
    }

    const onBackHandler = () => {
        history.goBack();
    }

    return (
        <>
            <div className="row">
                <div className="breadcrumb-container">
                    <ul>
                        <li>
                            <Link to={"#"}>System Setting</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li>
                            <Link to={"#"}>Classifications</Link>
                        </li>
                        <li><i className="fa fa-angle-right"></i></li>
                        <li><Link to={"#"}>Tasks</Link></li>
                    </ul>
                </div>
                <div className="title-body-container">
                    <div className="title-body">
                        <h3>Tasks</h3>
                        <p>Maintain user information - Add, edit, delete and invite users</p>
                    </div>
                    <div className="title-filter-container">
                        <div className="search-filter">
                            <input placeholder="Search" value={searchText} onChange={(e) => onSearchHandler(e.target.value)} />
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>

                        <div className="sort-filter">
                            <select
                                defaultValue={"option"}
                                className=""
                                value={sordBy}
                                onChange={(e) => onSordByHandler(e.target.value)}>
                                <option>Sort by</option>
                                <option value="title">Title</option>
                                <option value="description">Description</option>
                                <option value="activity">Activity</option>
                            </select>
                            <button className={sort === null ? null : sort ? "sorting-asc" : "sorting-dec"} onClick={onSordHandler}>
                                <i class="fa fa-long-arrow-up" aria-hidden="true"></i>
                                <i class="fa fa-long-arrow-down" aria-hidden="true"></i>
                            </button>
                        </div>

                        <div className="button-filter">
                            {/* <button className="add-user-btn" 
                             disabled={parseInt(props.userType) == 2 ?true:false}
                            onClick={() => onAddProjectHandler()}><i class="fa fa-plus" aria-hidden="true"></i></button> */}
                            <button className="add-filter-up-btn"><i class="fa fa-long-arrow-up" aria-hidden="true"></i></button>
                        </div>

                    </div>

                </div>
                <div className="account-setting-content">
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <div className="add-role-button-view activities-list">
                                    <div><button onClick={onBackHandler} className="activities-back-btn"><i class="fa fa-arrow-left" aria-hidden="true"></i></button></div>
                                    <div className="add-btn-group">
                                        <button disabled={isDisabled}><i class="fa fa-external-link" aria-hidden="true"></i></button>
                                        <button
                                            //onClick={() => onMultipleRemoveHandler()}
                                            disabled={isDisabled}>X</button>
                                        <button disabled={isDisabled}>S</button>
                                        <button
                                            //onClick={() => onDownloadHandler()}
                                            disabled={isDisabled}>
                                            <i class="fa fa-download" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                {props.screenVisible ?
                                    <>
                                        {props.taskList.length > 0 ?
                                            <Table responsive>
                                                <thead>
                                                    <tr>
                                                        {/* <th className="customer_shop">
                                                            <div className="form-check custom-checkbox mx-2">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="checkAll"
                                                                    onClick={(e) => chackboxFun('all', 0)}
                                                                />
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="checkAll"
                                                                ></label>
                                                            </div>
                                                        </th> */}
                                                        <th>
                                                            <strong>ID</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Description</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Project</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Activity</strong>
                                                        </th>
                                                        <th>
                                                            <strong>Status</strong>
                                                        </th>
                                                        {/* <th>
                                                            <strong>Actions</strong>
                                                        </th> */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        props.taskList.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    {/* <td>
                                                                        <div className="form-check custom-checkbox checkbox-select mx-2">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id={`check${index}`}
                                                                                onClick={(e) => chackboxFun(e, item)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor={`check${index}`}
                                                                            ></label>
                                                                        </div>
                                                                    </td> */}
                                                                    {/* <td>{item.task_id}</td> */}
                                                                    <td>
                                                                        <Link
                                                                            // to={{
                                                                            //     pathname: '/time-capture',
                                                                            //     state: item,
                                                                            // }}
                                                                            onClick={() => onTimeCaptureHandler(item)}
                                                                            className="project-title"
                                                                        >
                                                                            {item.task_id}
                                                                        </Link>
                                                                       
                                                                    </td>
                                                                    <td>{item.description}</td>
                                                                    <td>{item.project_name}</td>
                                                                    <td>{item.activity_name}</td>
                                                                    <td>
                                                                        <Button
                                                                            className="btn-xs"
                                                                            variant={item.status == 1 ? "success" : "danger"}
                                                                            //onClick={() => onStatusHandler(item)}
                                                                        >
                                                                            {item.status == 1 ? "Active" : "Inactive"}
                                                                        </Button>
                                                                    </td>
                                                                    {/* <td>
                                                                        <div className="d-flex">
                                                                            <Link
                                                                                onClick={() => onEditProjectHandler(item)}
                                                                                className="btn btn-warning shadow btn-xs sharp me-1"
                                                                            >
                                                                                <i className="fas fa-pencil-alt"></i>
                                                                            </Link>

                                                                            <Button
                                                                                className="btn btn-danger shadow btn-xs sharp me-1"
                                                                                onClick={() => onDeleteHandler(item._id)}
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </Button>

                                                                            <Link
                                                                                onClick={() => onViewProjectHandler(item)}
                                                                                className="btn btn-info shadow btn-xs sharp"
                                                                            >
                                                                                <i className="fa fa-external-link-square"></i>
                                                                            </Link>
                                                                        </div>
                                                                    </td> */}
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <div className="nodata-found-view">
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                <h2>Data not found</h2>
                                                <p>Activities Task data is empty or Try again search</p>
                                                <button type="button" onClick={onCleanHandler}>
                                                    Retry
                                                </button>
                                            </div>
                                        }
                                    </>
                                    : null}

                                <div className="pagination-container">
                                    {parseInt(props.totalTaskRecord) > 0 && props.showLoading == false ? (
                                        <div className="page-result alignResult">
                                            <p>Showing{" "}{(pageNo - 1) * perPage + 1 + " - " + ((pageNo - 1) * perPage + props.taskList.length) + " of " + "( " + props.totalTaskRecord + " items )"}</p>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {parseInt(props.totalTaskRecord) > 10 ? (
                                        <ReactPaginate
                                            containerClassName="pagination-gutter false pagination-circle pagination"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            breakLabel="..."
                                            nextLabel={<i class="fa fa-angle-right" aria-hidden="true"></i>}
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            previousLabel={<i class="fa fa-angle-left" aria-hidden="true"></i>}
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            renderOnZeroPageCount={null}
                                            activeClassName="active"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={3}
                                            pageCount={props.totalTaskPage}
                                            //initialPage={active - 1}
                                            forcePage={pageNo - 1}
                                        />
                                    ) : null}
                                </div>

                            </Card.Body>
                        </Card>
                    </Col>
                </div>


                <AddProjectTask
                    show={taskModal}
                    data={details}
                    action={taskAction}
                    option={optionsArray}
                    submitHandler={(data) => onSubmitTaskHandler(data)}
                    closeHandler={() => setTaskModal(false)}
                />

                <Modal
                    className="fade bd-example-modal-sm"
                    size="lg"
                    show={viewProjectModal}
                >
                    <div className="add-user-modal-form-view">
                        <div className="modal-title-view">
                            <h3>View Task</h3>
                            <p>View Project Task Details</p>
                        </div>
                        <div className="basic-form">

                            <div className="row">

                                <div className="col-md-6 mb-3">
                                    <div className="form-view-info">
                                        <h3>Project : <span>{title}</span></h3>
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <div className="form-view-info">
                                        <h3>Desc : <span>{description}</span></h3>
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <div className="form-view-info">
                                        <h3>Activity : <span>{activity}</span></h3>
                                    </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                    <div className="form-view-info">
                                        <h3>Status : <span>{status == 1 ? 'Active' : 'Inactive'}</span></h3>
                                    </div>
                                </div>

                            </div>

                            <div className="modal-button-group mt-4">
                                <button type="button" onClick={() => setViewProjectModal(false)} className="btn form-cencel-btn">
                                    Close
                                </button>
                            </div>

                        </div>

                    </div>
                </Modal>

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        screenVisible: state.project.screenVisible,
        showLoading: state.project.showLoading,
        taskList: state.project.taskList,
        groupKeeperList: state.employee.groupKeeperList,
        totalTaskPage: state.project.totalTaskPage,
        totalTaskRecord: state.project.totalTaskRecord,
        systemAdmin: state.auth.systemAdmin,
        userType: state.auth.userType,
    };
};
export default connect(mapStateToProps)(ClientTask);